.nav {
    width: 100%;
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.header {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.background {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    animation-name: zoomBanner;
    animation-duration: 8s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes zoomBanner {
    from {
        transform: scale(1.3);
    }

    to {
        transform: translateX(1);
    }
}