@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Comfortaa', cursive;
}

.bor {
  border: 1px solid red;
}

.menu .active {
  border-bottom: 1px solid #FDBA74;
}

.sideMenu .active {
  background-color: #061B2B;
  color: white;
}

#whoWeAre {
  background-image: url(./assets/banner.png);
}